import {
	projectFirestore,
	projectAuth,
	timestamp,
	timestampFromDate,
} from "../firebase/config";
import getCollection from "./getCollection";
import useCookies from "./useCookies";
import { ref } from "vue";
import locationCollection from "./locationCollection";
import { v4 as uuidv4 } from "uuid";
import { serverTimestamp } from "firebase/firestore";
const equipmentCollection = () => {
	const { getCookie } = useCookies();
	const equipments = ref(null);
	const error = ref("");
	const user = projectAuth.currentUser.uid;
	const companyId = getCookie("rm_cId");
	const actionId = getCookie("rm_aId");
	const { getLocation, locations, err } = locationCollection();
	getLocation();

	const getEquipment = async () => {
		await projectFirestore
			.collection("companies")
			.doc(companyId)
			.collection("employees")
			.doc(user)
			.get()
			.then((doc) => {
				let employeeDoc = doc.data();
				try {
					let collectionRef = projectFirestore
						.collection("companies")
						.doc(companyId)
						.collection("equipment")
						.where(
							"locationId",
							"array-contains-any",
							employeeDoc.locations
						);

					const { documents: items } = getCollection(collectionRef);
					equipments.value = items;
				} catch (err) {
					console.log(err.message);
					error.value = err.message;
				}
			});
	};

	const getEquipmentByIds = async (companyId, equipmentIds) => {
		try {
			let collectionRef = projectFirestore
				.collection("companies")
				.doc(companyId)
				.collection("equipment");

			let items = [];

			if (equipmentIds.length === 1) {
				const docRef = collectionRef.doc(equipmentIds[0]);
				const result = await docRef.get();
				if (result.exists) {
					items.push({ id: result.id, ...result.data() });
				}
			} else {
				const queryRef = collectionRef.where(
					"identifier",
					"in",
					equipmentIds
				);
				const result = await queryRef.get();
				result.forEach((doc) => {
					items.push({ id: doc.id, ...doc.data() });
				});
			}

			return items;
		} catch (err) {
			console.error(err.message);
			error.value = err.message;
		}
	};

	const updateEquipmentTag = async (tag) => {
		const companyId = getCookie("rm_cId");
		try {
			const tagdata = await projectFirestore
				.collection("companies")
				.doc(companyId)
				.collection("equipmenttags")
				.where("tag", "==", tag)
				.get();
			if (tagdata.docs.length == 0) {
				await projectFirestore
					.collection("companies")
					.doc(companyId)
					.collection("equipmenttags")
					.add({ tag: tag });
			}
		} catch (err) {
			console.log(err);
			error.value = err.message;
		}

		return { error };
	};

	const updateEquipmentNames = async (name) => {
		const companyId = getCookie("rm_cId");
		try {
			const tagdata = await projectFirestore
				.collection("companies")
				.doc(companyId)
				.collection("equipmentnames")
				.where("name", "==", name)
				.get();
			if (tagdata.docs.length == 0) {
				await projectFirestore
					.collection("companies")
					.doc(companyId)
					.collection("equipmentnames")
					.add({ name: name });
			}
		} catch (err) {
			console.log(err);
			error.value = err.message;
		}

		return { error };
	};

	const getEquipmentTags = async () => {
		const companyId = getCookie("rm_cId");

		var tags = [];
		const collectionRefdata = await projectFirestore
			.collection("companies")
			.doc(companyId)
			.collection("equipment")
			.get();

		collectionRefdata.docs.forEach((doc) => {
			doc.data().tags.forEach((tag) => {
				if (!tags.includes(tag)) {
					tags.push(tag);
				}
			});
		});

		return tags;
	};

	const refreshEquipmentTags = async (id) => {
		const companyId = id;

		const eRef = projectFirestore
			.collection("companies")
			.doc(companyId)
			.collection("equipment");
		const eNamesRef = projectFirestore
			.collection("companies")
			.doc(companyId)
			.collection("equipmentnames");
		const eTagsRef = projectFirestore
			.collection("companies")
			.doc(companyId)
			.collection("equipmenttags");

		// Get equipment list
		const equipment = await eRef.get();

		let equipmentNames = [];
		let equipmentTags = [];

		equipment.docs.forEach((doc) => {
			let item = doc.data();

			// Add unique names
			if (!equipmentNames.includes(item.name)) {
				equipmentNames.push(item.name);
			}

			// Add unique tags
			item.tags.forEach((tag) => {
				if (!equipmentTags.includes(tag)) {
					equipmentTags.push(tag);
				}
			});
		});

		// Sync equipment names
		let eNames = await eNamesRef.get();

		const collectionNames = new Set(
			eNames.docs.map((doc) => doc.data().name)
		);
		const deleteNames = Array.from(collectionNames).filter(
			(name) => !equipmentNames.includes(name)
		);

		let namePromises = [];

		for (const name of deleteNames) {
			const doc = eNames.docs.find((doc) => doc.data().name === name);
			namePromises.push(doc.ref.delete());
		}

		const addNames = equipmentNames.filter(
			(name) => !collectionNames.has(name)
		);

		for (const name of addNames) {
			namePromises.push(eNamesRef.add({ name: name }));
		}

		// Sync equipment tags
		let eTags = await eTagsRef.get();

		const collectionTags = new Set(eTags.docs.map((doc) => doc.data().tag));
		const deleteTags = Array.from(collectionTags).filter(
			(tag) => !equipmentTags.includes(tag)
		);

		let tagPromises = [];

		for (const tag of deleteTags) {
			const doc = eTags.docs.find((doc) => doc.data().tag === tag);
			tagPromises.push(doc.ref.delete());
		}

		const addTags = equipmentTags.filter((tag) => !collectionTags.has(tag));

		for (const tag of addTags) {
			tagPromises.push(eTagsRef.add({ tag: tag }));
		}

		await Promise.all(tagPromises);

		await Promise.all(namePromises);
	};

	const getEquipmentNames = async () => {
		const companyId = getCookie("rm_cId");

		var names = [];
		const collectionRefdata = await projectFirestore
			.collection("companies")
			.doc(companyId)
			.collection("equipmentnames")
			.get();
		collectionRefdata.docs.forEach((doc) => {
			var name = doc.data().name;
			if (!names.includes(name) && name !== "") {
				names.push(name);
			}
		});

		return names;
	};

	const syncEquipmentNames = async (companyId) => {
		const user = projectAuth.currentUser;

		if (user.email != "admin@riskmemo.com") {
			console.error(
				"Only risk memo admin can synchronize equipment names."
			);
			return;
		}

		const companyRef = projectFirestore
			.collection("companies")
			.doc(companyId);

		try {
			// Get all equipment documents where archived is false
			const equipmentSnapshot = await companyRef
				.collection("equipment")
				.where("archived", "==", false)
				.get();
			const equipments = equipmentSnapshot.docs.map((doc) => ({
				id: doc.id,
				...doc.data(),
			}));

			// Get all documents from equipmentnames collection
			const equipmentNamesSnapshot = await companyRef
				.collection("equipmentnames")
				.get();
			const equipmentNames = equipmentNamesSnapshot.docs.map((doc) => ({
				id: doc.id,
				...doc.data(),
			}));

			// Create a list of current names from the equipment collection
			const currentEquipmentNames = equipments.map(
				(equipment) => equipment.name
			);

			// Delete documents in equipmentnames where the name is not in the current equipment names list
			for (const equipmentName of equipmentNames) {
				if (!currentEquipmentNames.includes(equipmentName.name)) {
					await companyRef
						.collection("equipmentnames")
						.doc(equipmentName.id)
						.delete();
				}
			}

			// Find names that need to be added to equipmentnames collection
			const namesToAdd = currentEquipmentNames.filter(
				(name) =>
					!equipmentNames.some(
						(equipmentName) => equipmentName.name === name
					)
			);

			// Add missing names to the equipmentnames collection
			for (const name of namesToAdd) {
				await companyRef
					.collection("equipmentnames")
					.add({ name: name });
			}

			console.log("Equipment names synchronized successfully.");
		} catch (error) {
			console.error("Failed to synchronize equipment names:", error);
		}
	};

	const deleteEquipmentNames = async (name) => {
		const tagdata = await projectFirestore
			.collection("companies")
			.doc(companyId)
			.collection("equipmentnames")
			.where("name", "==", name)
			.get();
		if (tagdata.docs.length > 0) {
			tagdata.docs.forEach((doc) => {
				projectFirestore
					.collection("companies")
					.doc(companyId)
					.collection("equipmentnames")
					.doc(doc.id)
					.delete();
			});
		}
	};

	const deleteEquipmentTag = async (name) => {
		const tagdata = await projectFirestore
			.collection("companies")
			.doc(companyId)
			.collection("equipmenttags")
			.where("tag", "==", name)
			.get();
		if (tagdata.docs.length > 0) {
			tagdata.docs.forEach((doc) => {
				projectFirestore
					.collection("companies")
					.doc(companyId)
					.collection("equipmenttags")
					.doc(doc.id)
					.delete();
			});
		}
	};

	const createExampleEquipment = async (location) => {
		const batch = projectFirestore.batch();

		for (let i = 1; i <= 5; i++) {
			const equipmentId = `EI00${i}`;
			const equipment = {
				name: `Example Item`,
				identifier: equipmentId,
				locationName: location.locationName,
				locationId: location.locationId,
				color: "darkorange",
				initials: `EI`,
				tags: [`Example Item`, location.locationName],
				createdAt: timestamp(),
				lastUpdated: timestamp(),
				archived: false,
				author: projectAuth.currentUser.uid,
				doNotUseDate: null,
				checkedStatus: "Safe for use",
			};

			const equipmentRef = projectFirestore
				.collection("companies")
				.doc(location.companyId)
				.collection("equipment")
				.doc(equipmentId);

			batch.set(equipmentRef, equipment);
		}

		await batch.commit();
	};

	// Add Note
	const addEquipmentNote = async (equipmentId, note, noteId, userId) => {
		const notedata = projectFirestore
			.collection("companies")
			.doc(companyId);

		const newNote = {
			content: note.noteDesc,
			// assignedDate: note.assignedDate,
			// assignedPerson: note.assignedPerson,
			createdAt: timestamp(),
			updatedAt: timestamp(),
			id: noteId,
			ownerId: userId,
			isArchived: false,
		};

		await notedata
			.collection("equipment")
			.doc(equipmentId)
			.collection("Notes")
			.doc(noteId)
			.set(newNote);
	};

	const addEquipmentDoc = async (equipmentId, id, fileDoc) => {
		const docdata = projectFirestore.collection("companies").doc(companyId);

		await docdata
			.collection("equipment")
			.doc(equipmentId)
			.collection("storage")
			.doc(id)
			.set(fileDoc);
	};

	const addEquipmentImage = async (equipmentId, id, fileImage) => {
		const imagedata = projectFirestore
			.collection("companies")
			.doc(companyId);

		await imagedata
			.collection("equipment")
			.doc(equipmentId)
			.collection("storage")
			.doc(id)
			.set(fileImage);
	};

	//Get Note
	const getEquipmentNote = async (equipmentId, companyId) => {
		const notedata = projectFirestore
			.collection("companies")
			.doc(companyId);
		const notesRefData = await notedata
			.collection("equipment")
			.doc(equipmentId)
			.collection("Notes")
			.where("isArchived", "==", false)
			.get();

		var notes = [];
		notesRefData.docs.forEach((doc) => {
			var retriveNote = {
				content: doc.data().content,
				title: doc.data().title,
				assignedPerson: doc.data().assignedPerson,
				assignedDate: doc.data().assignedDate,
				id: doc.data().id,
			};
			notes.push(retriveNote);
		});
		return notes;
	};
	//update Note
	const updateEquipmentNote = async (equipmentId, note, noteId, userId) => {
		const notedata = projectFirestore
			.collection("companies")
			.doc(companyId);

		const newNote = {
			content: note.noteDesc,
			// assignedPerson: note.assignedPerson,
			// assignedDate: note.assignedDate,
			updatedAt: timestamp(),
			id: noteId,
			ownerId: userId,
		};

		await notedata
			.collection("equipment")
			.doc(equipmentId)
			.collection("Notes")
			.doc(noteId)
			.update(newNote);
	};

	//Delete Note
	const deleteEquipmentNote = async (companyId, equipmentId, noteId) => {
		const notedata = projectFirestore
			.collection("companies")
			.doc(companyId);
		await notedata
			.collection("equipment")
			.doc(equipmentId)
			.collection("Notes")
			.doc(noteId)
			// .delete();
			.update({ isArchived: true });
	};

	//Add Action
	const addEquipmentAction = async (
		userId,
		actionId,
		action,
		equipmentName,
		identifier
	) => {
		const actiondata = projectFirestore
			.collection("EquipmentAction")
			.doc(actionId);

		const newAction = {
			description: action.actionDesc,
			status: action.status,
			state: action.state,
			assignedDate: action.assignedDate,
			assignedPerson: action.assignedPerson,
			createdAt: timestamp(),
			updatedAt: timestamp(),
			id: actionId,
			ownerId: userId,
			companyId: companyId,
			equipmentName: equipmentName,
			identifier: identifier,
		};

		await actiondata.set(newAction);
	};

	const getEmployees = async () => {
		const employeesRef = projectFirestore
			.collection("companies")
			.doc(companyId)
			.collection("employees")
			.where("isActive", "==", true);
		const employeesdata = await employeesRef.get();
		const employees = employeesdata.docs.map((doc) => ({
			id: doc.id,
			name: doc.data().profile.fullName,
		}));
		return employees;
	};

	//Get Action
	const getEquipmentAction = async (identifier) => {
		const actiondata = projectFirestore
			.collection("EquipmentAction")
			.where("identifier", "==", identifier);

		const actionsRefData = await actiondata.get();

		var actions = [];

		actionsRefData.forEach((doc) => {
			var retriveAction = {
				description: doc.data().description,
				status: doc.data().status,
				state: doc.data().state,
				assignedPerson: doc.data().assignedPerson,
				assignedDate: doc.data().assignedDate,
				id: doc.data().id,
			};
			actions.push(retriveAction);
		});

		return actions;
	};

	//Update Action
	const updateEquipmentAction = async (actionId, action) => {
		const actiondata = projectFirestore
			.collection("EquipmentAction")
			.doc(actionId);

		const updateAction = {
			description: action.actionDesc,
			status: action.status,
			state: action.state,
			assignedPerson: action.assignedPerson,
			assignedDate: action.assignedDate,
			updatedAt: timestamp(),
		};
		await actiondata.update(updateAction);
	};

	// Delete Action
	const deleteEquipmentAction = async (actionId) => {
		const actiondata = projectFirestore
			.collection("EquipmentAction")
			.doc(actionId);
		await actiondata.delete();
	};

	const bulkEquipmentUpdate = async (
		equipment,
		data,
		addTagsList,
		deleteTagList,
		archiveAll
	) => {
		const companyId = getCookie("rm_cId");
		let equipmentRef = projectFirestore
			.collection("companies")
			.doc(companyId)
			.collection("equipment");

		try {
			let batch = projectFirestore.batch();

			console.log("bulkEquipmentUpdate");
			console.log("data: ", data);
			console.log("equipment: ", equipment);

			// Data - the original list of items to update
			// Equipment - the updates to make to every item

			data.forEach((item) => {
				equipment.identifier = item.id;

				var tags = item.tags;

				// If the item name is included in the update
				if (equipment.name) {
					// Set the equipment Name and Initials
					let nameWords = equipment.name
						.replace(/\s+/g, " ")
						.split(" ");

					let tempInitials;

					if (nameWords.length == 1) {
						tempInitials = nameWords.shift().charAt(0);
					} else {
						tempInitials =
							nameWords.shift().charAt(0) +
							nameWords.pop().charAt(0);
					}

					tempInitials.toUpperCase();

					equipment.initials = tempInitials;

					// Add item name to tags if not already included
					if (!item.tags.includes(equipment.name)) {
						tags.push(equipment.name);
					}
				}

				if (deleteTagList.value.length > 0) {
					deleteTagList.value.forEach((tagToDelete) => {
						tags = tags.filter((item) => {
							return item !== tagToDelete;
						});
					});
				}

				if (addTagsList.value.length > 0) {
					addTagsList.value.forEach((tagToAdd) => {
						if (!item.tags.includes(tagToAdd)) {
							tags.push(tagToAdd);
						}
					});
				}

				equipment.tags = tags;

				let setItemRef = equipmentRef.doc(item.id);

				if (archiveAll) {
					equipment.archived = true;
				}

				batch.update(setItemRef, equipment);
			});
			await batch.commit();
		} catch (e) {
			console.error(e.message);
			error.value = e.message;
		}
	};

	return {
		syncEquipmentNames,
		refreshEquipmentTags,
		getEquipment,
		getEquipmentByIds,
		equipments,
		error,
		updateEquipmentTag,
		updateEquipmentNames,
		getEquipmentTags,
		getEquipmentNames,
		deleteEquipmentNames,
		deleteEquipmentTag,
		createExampleEquipment,
		bulkEquipmentUpdate,
		addEquipmentNote,
		getEquipmentNote,
		deleteEquipmentNote,
		updateEquipmentNote,
		addEquipmentAction,
		getEquipmentAction,
		updateEquipmentAction,
		deleteEquipmentAction,
		getEmployees,
		addEquipmentDoc,
		addEquipmentImage,
	};
};

export default equipmentCollection;
